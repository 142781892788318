import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
//import Button from "react-bootstrap/Button"
//import scrollTo from "gatsby-plugin-smoothscroll";
import Container from "react-bootstrap/Container";
import "./header.css";
import logo from "../images/logo.png";

const Header = ({ siteTitle }) => (
  <Navbar expand="lg" className="header" fixed="top" bg="light">
    <Container fluid>
      <Navbar.Brand className="logo">
        <Link to="/">
          <img
            src={logo}
            alt="Orijin Interactive Design Co logo"
            className="d-inline-block align-top ologo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {/* <Nav className="ml-auto">
          <Nav.Link onClick={() => scrollTo("#about")}>About</Nav.Link>
          <Nav.Link onClick={() => scrollTo("#cert")}>Certification</Nav.Link>
          <Nav.Link onClick={() => scrollTo("#contact")}>Contact Us</Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
