import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
//import scrollTo from "gatsby-plugin-smoothscroll";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

// images

// css
import "../components/app.scss";
import "../components/index.scss";

export default function Home() {
  return (
    <Layout>
      <SEO title="Orijin Interactive Design Co" />
      <Jumbotron>
        <Container className="pt-5 mt-5">
          <Row>
            <Col className="movecenter">
              <h1>Result Driven Digital Designs.</h1>
              <div>
                <p>
                  We help great companies meet their business goals
                  <br />
                  using design
                </p>
                <Button
                  variant="primary"
                  size="lg"
                  href="https://calendly.com/theorijinco/discovery"
                  target="_blank"
                  className="butSpace"
                >
                  Request a FREE call
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </Layout>
  );
}
